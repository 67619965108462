import { useEffect } from "react";

const MatomoTracker = ({ siteId, matomoUrl, matomoScriptSrc }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.defer = true;
    script.src = `${matomoScriptSrc}/matomo.js`;
    document.body.appendChild(script);

    window._paq = window._paq || [];
    window._paq.push(["trackPageView"]);
    window._paq.push(["enableLinkTracking"]);
    window._paq.push(["setTrackerUrl", `${matomoUrl}/matomo.php`]);
    window._paq.push(["setSiteId", siteId]);
  }, [siteId, matomoUrl, matomoScriptSrc]);

  return null;
};

export default MatomoTracker;
