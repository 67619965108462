import * as Sentry from "@sentry/react";
import React, { lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Roobert/roobert.css";
import "./assets/fonts/nib/nib.css";
import MatomoTracker from "./lib/MatomoTracker.jsx";
import "./styles/globals.css";
import "./styles/reset.css";
import "./styles/tailwind.css";

if (process.env.NODE_ENV === "production" && import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    initialScope: {
      tags: { mode: import.meta.env.VITE_MODE },
    },
  });
}

let App;

if (import.meta.env.VITE_MODE === "show") {
  App = lazy(() => import("./show/ShowApp.jsx"));
} else {
  App = lazy(() => import("./App.jsx"));
}

let StagingBanner;

if (process.env.NODE_ENV.startsWith("staging")) {
  StagingBanner = lazy(() => import("./staging/StagingBanner.jsx"));
}

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      {StagingBanner && <StagingBanner />}
      <App />

      {process.env.NODE_ENV === "production" && (
        <MatomoTracker
          siteId={import.meta.env.VITE_MATOMO_SITE_ID}
          matomoUrl={import.meta.env.VITE_MATOMO_URL}
          matomoScriptSrc={import.meta.env.VITE_MATOMO_SCRIPT_SRC}
        />
      )}
    </BrowserRouter>
  </React.StrictMode>,
);
